import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Spinner } from './components/Indicators';
import { firebase } from './utils/firebase';
import ProtectedRoute from './components/ProtectedRoute';
import { Login, SignUp, FindAccount } from './pages/Accounts';
import { BusinessApi } from './utils/apis';

import './App.css';
import 'react-activity/dist/react-activity.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-simple-line-icons';

const Errors = React.lazy(() => import('./pages/Error'));
const Policies = React.lazy(() => import('./pages/Policies'));

class App extends Component {
  
  constructor(props) {
    super(props);
    this.state = { loading: true, authenticated: false, domain: null, country: null };
  }

  UNSAFE_componentWillMount() {
    firebase.checkAuth(user => {
      if (user) {
        BusinessApi.setUser(user);
        BusinessApi.getToken().then(response => {
          if(response.status == 200) {
                const { token, domain, country } = response.data;
                BusinessApi.setToken(token);
                BusinessApi.setDomain(domain);
                this.setState({authenticated: true, loading: false, domain, country });
          } else throw 'token error';
    
        }).catch(error => {
            console.log('catch', error);
            //self.props.history.push('/error/500')
            window.location.href = '#/error/500';
        });

      } else {
        this.setState({authenticated: false, loading: false});
      }
    });
  }

  render() {
    const { authenticated, loading, domain, country } = this.state;
    
    if (loading) {
      return (<Spinner />);
    }

    return (
        <HashRouter>
          <React.Suspense fallback={<Spinner />}>
            <Switch>
              <Route exact path='/login' name="로그인" render={props => <Login {...props}/>} />
              <Route exact path='/signup' name="가입하기" render={props => <SignUp {...props}/>} />
              <Route exact path='/forgot' name="비밀번호찾기" render={props => <FindAccount {...props}/>} />
              <Route exact path='/error/:code' name="오류" render={props => <Errors {...props}/>} />
              <Route exact path='/policy/:page' name="이용정책" render={props => <Policies {...props}/>} />
              <ProtectedRoute path="/" name="파트너센터" authenticated={authenticated} domain={domain} country={country} />
            </Switch>
          </React.Suspense>
        </HashRouter>
    );
  }
}

export default App;
