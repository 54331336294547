import axios from 'axios';

class Apis {
    constructor() {
        this.instance = axios.create({
            baseURL: process.env.REACT_APP_API_HOST,
            headers: {
                'Content-Type': 'application/json', 
                'X-Api-Key': 'wOzYKC4w14toM6zmg6Dk8WNpId73ESn63kX5hvMj'
            }
        });
        this.token = '';
        this.uid = '';
        this.utype = '';
        this.displayName = '';
        this.domain = '';
    }

    setUser(user) {
        this.uid = user.uid;
        this.utype = 'master';
        this.displayName = user.displayName;
    }

    checkBiz(bizNo, bizName) {
        return this.instance.get('/account/verify', {
            params: { no: bizNo, name: bizName }
        });
    }

    sendAuthCode(phone) {
        return this.instance.post('/account/verify', { phone});
    }

    verifyPhone(phone, code) {
        return this.instance.put('/account/verify', { phone, code });
    }

    signup(params) {
        return this.instance.post('/account/create', params);
    }

    getToken(domain) {
        return this.instance.get('/token', {
            params: { 
                key: this.uid,
                type: this.utype,
                place: domain
            }
        });
    }

    setToken(token) {
        this.token = token;
        this.instance.defaults.headers.common['Authorization'] = 'Bearer '+token;
        sessionStorage.setItem('token', token);
    }

    setDomain(domain) {
        this.domain = domain;
    }

    getPlaces() {
        return this.instance.get('/places', {
            params: { 
                uid: this.uid,
                utype: this.utype
            }
        });
    }

    getPlace(domain) {
        return this.instance.get('/places/'+domain);
    }

    addPlace(params) {
        return this.instance.post('/places/create', params);
    }

    updatePlace(domain, params) {
        return this.instance.put('/places/'+domain, params);
    }

    updatePlaceLogo(params) {
        return this.instance.put('/places/logo', params);
    }

    removePlace(domain) {
        return this.instance.delete('/places/'+domain);
    }

    getPlaceStatus() {
        return this.instance.get('/places/status');
    }

    addPlaceTime(params) {
        return this.instance.post('/places/times/create', params);
    }

    removePlaceTime(timeId) {
        return this.instance.delete('/places/times/'+timeId);
    }

    addPlacePhoto(params) {
        return this.instance.post('/places/photos/create', params);
    }

    removePlacePhoto(photoId) {
        return this.instance.delete('/places/photos/'+photoId);
    }

    getWallet() {
        return this.instance.get('/wallets/status');
    }

    getWalletPays() {
        return this.instance.get('/wallets/payments');
    }

    getWalletItems(params) {
        return this.instance.get('/wallets/items', {params});
    }

    getWalletInvoices() {
        return this.instance.get('/wallets/invoices');
    }

    getWalletVouchers() {
        return this.instance.get('/wallets/vouchers');
    }

    getWalletCheckout(params) {
        return this.instance.get('/wallets/checkout', {params});
    }

    checkoutWalletItem(params) {
        return this.instance.post('/wallets/checkout', params);
    }

    chargeWallet() {
        return this.instance.post('/wallets/charging');
    }

    getCustomers(params) {
        return this.instance.get('/customers', {params});
    }

    updateCustomer(params) {
        return this.instance.put('/customers/'+params.id, params);
    }

    getCategories(ctype) {
        return this.instance.get('/categories', {
            params: { ctype: ctype }
        });
    }

    addCategory(ctype, title) {
        return this.instance.post('/categories/create', { ctype: ctype, title: title });
    }

    updateCategory(categoryId, title) {
        return this.instance.put('/categories/'+categoryId, { category: categoryId, title: title });
    }

    removeCategory(categoryId) {
        return this.instance.delete('/categories/'+categoryId);
    }

    getItems() {
        return this.instance.get('/items');
    }

    getItem(itemId) {
        return this.instance.get('/items/'+itemId);
    }

    addItem(params) {
        return this.instance.post('/items/create', params);
    }

    updateItem(itemId, params) {
        return this.instance.put('/items/'+itemId, params);
    }

    removeItem(itemId) {
        return this.instance.delete('/items/'+itemId);
    }

    addItemImage(params) {
        return this.instance.post('/items/image/create', params);
    }

    removeItemImage(imageId) {
        return this.instance.delete('/items/image/'+imageId);
    }

    getOptions(itemId) {
        return this.instance.get('/items/'+itemId+'/options');
    }

    addOption(params) {
        return this.instance.post('/items/option/create', params);
    }

    updateOption(params) {
        return this.instance.put('/items/option/'+params.id, params);
    }

    removeOption(params) {
        return this.instance.delete('/items/option/'+params.id, { data: params });
    }

    addOptionCategory(params) {
        return this.instance.post('/items/option/category/create', params);
    }

    updateOptionCategory(itemId, params) {
        params['item'] = itemId;
        return this.instance.put('/items/option/category/'+params.id, params);
    }

    removeOptionCategory(itemId, params) {
        return this.instance.delete('/items/option/category/'+params.id, { data: { 'item': itemId } });
    }

    getTables() {
        return this.instance.get('/tables');
    }

    addTable(params) {
        return this.instance.post('/tables/create', params);
    }

    updateTable(tableId, params) {
        return this.instance.put('/tables/'+tableId, params);
    }

    removeTable(tableId) {
        return this.instance.delete('/tables/'+tableId);
    }

    clearTable(tableId) {
        return this.instance.post('/tables/'+tableId+'/clear');
    }

    getOrders(startDate, endDate, status) {
        return this.instance.get('/orders', {
            params: { start: startDate, end: endDate, status: status }
        });
    }

    addOrder(params) {
        return this.instance.post('/orders/create', params);
    }

    updateOrder(orderKey, status) {
        return this.instance.put('/orders/'+orderKey, { status: status, domain: this.domain });
    }

    cancelOrder(orderKey) {
        return this.instance.post('/orders/'+orderKey+'/cancel');
    }

    getWaitings(params) {
        return this.instance.get('/waitings', { params });
    }

    addWaiting(params) {
        return this.instance.post('/waitings/create', params);
    }

    updateWaiting(waitingKey, action) {
        // window.gtag('event', '웨이팅', {
        //     'event_category': '플레이스',
        //     'event_label': '퀵 웨이팅'
        // });
        return this.instance.post('/waitings/'+waitingKey, {'action': action });
    }

    callWaitingGroup(params) {
        return this.instance.post('/waitings/call', params);
    }

    getWaitingGroups() {
        return this.instance.get('/waitings/groups');
    }

    addWaitingGroup(params) {
        return this.instance.post('/waitings/groups/create', params);
    }

    updateWaitingGroup(groupId, params) {
        return this.instance.put('/waitings/groups/'+groupId, params);
    }

    removeWaitingGroup(groupId) {
        return this.instance.delete('/waitings/groups/'+groupId);
    }

    getWaitingCallers() {
        return this.instance.get('/waitings/callers');
    }

    addWaitingCaller(params) {
        return this.instance.post('/waitings/callers/create', params);
    }

    updateWaitingCaller(callerId, params) {
        return this.instance.put('/waitings/callers/'+callerId, params);
    }

    removeWaitingCaller(callerId) {
        return this.instance.delete('/waitings/callers/'+callerId);
    }

    getGuides(service) {
        return this.instance.get('/guides', { params: { service: service } });
    }
    
    addGuide(seq, guide, service) {
        return this.instance.post('/guides/create', { 
            'seq': seq, 'text': guide, 'service': service 
        });
    }

    updateGuide(guideId, guide) {
        return this.instance.put('/guides/'+guideId, {'text': guide});
    }

    removeGuide(guideId) {
        return this.instance.delete('/guides/'+guideId);
    }

    getPreferences() {
        return this.instance.get('/preferences');
    }

    setPreferences(service, params) {
        return this.instance.put('/preferences/'+service, params);
    }

    getTemplates(params) {
        return this.instance.get('/preferences/templates', { params });
    }

    getStatistics(type, term, start, end) {
        return this.instance.get('/statistics/'+type, {
            params: {
                'term': term,
                'start': start,
                'end': end
            }
        });
    }

    getMessages() {
        return this.instance.get('/preferences/messages');
    }

    getDevices() {
        return this.instance.get('/preferences/devices');
    }

    getNotices() {
        return this.instance.get('/posts',{params: {board: 'notice'}});
    }

    getPosts(board) {
        return this.instance.get('/posts',{params: {board}});
    }

    getPostsRecently(board) {
        return this.instance.get('/posts/recently', {
            params: {board: board}
        });
    }

    getNotifications() {
        return this.instance.get('/notifications');
    }

    searchAddress(query) {
        const headers = { Authorization: 'KakaoAK 2e1772d1785925fde10d280c5f96cbc3' };
        const params = { query: query, page: 1, size: 30 };
        return axios.get('https://dapi.kakao.com/v2/local/search/address.json', { headers, params });
    }
}

export const BusinessApi = new Apis();