import React from "react";
import { Route, Redirect } from "react-router-dom";
import { DefaultLayout, GlobalLayout } from '../containers';

/*
    let layout = 'default';
    const hostname = window && window.location && window.location.hostname;
    const path = window.location.path;
    switch(path) {
      case 'ticketing':
        layout = 'ticketing';
        break;
      default:
        layout = 'default';
    }
*/

export default function ProtectedRoute({authenticated, country, ...rest}) {

  // let Layout = DefaultLayout;
  // switch(prefs.template) {
  //   case 'Recruiting':
  //   case 'Exhibition':
  //   case 'Ticketing':
  //   default:
  //     Layout = DefaultLayout;
  // }
  return (
    authenticated === true ? 
    (<Route {...rest} render={props => country==='US'?<GlobalLayout {...props} {...rest}  />:<DefaultLayout {...props} {...rest} />} />)
    :(<Redirect to="/login" />)
  );
}