import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
    apiKey: "AIzaSyBNo9K2IojZBnuNlQ2JEMhLOaPpGLnhzvI",
    authDomain: "coconutbusiness.firebaseapp.com",
    databaseURL: "https://coconutbusiness.firebaseio.com",
    projectId: "coconutbusiness",
    storageBucket: "coconutbusiness.appspot.com",
    messagingSenderId: "702188181142"
};

const legacy = {
    apiKey: "AIzaSyDhCLo8DQqlsDXwfLSukstMEhdmGZYCyPg",
    authDomain: "coconut-waiting-biz.firebaseapp.com",
    databaseURL: "https://coconut-waiting-biz.firebaseio.com",
    projectId: "coconut-waiting-biz",
    storageBucket: "coconut-waiting-biz.appspot.com",
    messagingSenderId: "886312626353"
};


class Firebase {
    constructor() {
        this.defaultApp = app.initializeApp(legacy);
        this.bisinessApp = app.initializeApp(config, 'business');
        this.auth = this.defaultApp.auth();
        this.db = this.defaultApp.database();
        this.firestore = this.bisinessApp.firestore();
    }

    getUid = () => this.auth.currentUser.uid
    doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);
    doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);
    doSignInWithToken = (token) => this.auth.signInWithCustomToken(token);
    doSignOut = () => this.auth.signOut();
    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
    doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);
    checkAuth = (user) => this.auth.onAuthStateChanged(user);
    //waitings = () => this.db.ref('waiting/'+this.getUid());
    waitings = (placeKey, group) => this.firestore.collection('waitings').doc(placeKey).collection(group);
    waitingGroup = (group) => this.db.ref('waitings/'+this.getUid()+'/'+group);
    orders = (placeKey, floor) => this.firestore.collection('orders').doc(placeKey).collection(floor);
    tableOrders = (placeKey, floor) => this.firestore.collection('orders').doc(placeKey).collection(floor);
    wallets = (walletKey) => this.firestore.collection('wallets').doc(walletKey);
    requestReward = (placeKey) => this.firestore.collection('rewards').doc(placeKey);
    upload = (storageName, path, file, metadata) => {
        const storage = this.bisinessApp.storage(storageName).ref();
        return storage.child(path).put(file, metadata);
    };
    uploadData = (storageName, path, dataUrl) => {
        const storage = this.bisinessApp.storage(storageName).ref();
        return storage.child(path).putString(dataUrl, 'data_url');
    };
    //getMySequence = (placeKey, group, waitingNum) => this.firestore.collection('waitings').doc(placeKey).collection(group).where('num', '<=', waitingNum);
}

export const firebase = new Firebase();